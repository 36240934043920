<template>
    <div style="z-index:111111">                                              
        <span v-for="(results,i) in this.info.results" :key="results.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
            <span>
                <h4 class="text-black pt-20" style="font-size: 1rem;" >{{results.word}} 
                    (<small class="pl-0" v-for="n in results.lexicalEntries" :key="n.id">{{n.lexicalCategory.text}},</small>)
                </h4>
                <span style="display:flex">
                    <h4 class="text-black pr-1">Definitions: </h4> 
                    <span style="display:block;text-align:left">
                        <span v-for="(data,i) in results.lexicalEntries" :key="data.id" v-bind:class="[i <= 2 ? 'show' : 'hidden']">
                            <span v-for="(entries,i) in data.entries" :key="entries.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
                                <span v-for="(senses,i) in entries.senses" :key="senses.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
                                    <span v-for="(synonyms,i) in senses.definitions" :key="synonyms.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
                                        <span >
                                        <b class="text-black font-normal pb-0 font-600" style="white-space: normal;">{{synonyms}},</b>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
                <span style="display:flex"><h4 class="text-black pr-1">Synonyms: </h4> 
                    <span v-for="(data,i) in results.lexicalEntries" :key="data.id" v-bind:class="[i < 2 ? 'show' : 'hidden']">
                        <span v-for="(entries,i) in data.entries" :key="entries.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
                            <span  v-for="(senses,i) in entries.senses" :key="senses.id" v-bind:class="[i < 1 ? 'show' : 'hidden']">
                                <span style="display:flex">
                                    <span v-for="(synonyms,i) in senses.synonyms" :key="synonyms.id" v-bind:class="[i < 2 ? 'show' : 'hidden']">
                                        <span style="display:flex">
                                        <b class="text-black font-normal pb-0 font-600" style="white-space: normal;">{{synonyms.text}},</b>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
                <span style="display:flex"><h4 class="text-black pr-1">Examples:</h4> 
                    <span v-for="(data,i) in results.lexicalEntries" :key="data.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
                        <span v-for="(entries,i) in data.entries" :key="entries.id" v-bind:class="[i === 0 ? 'show' : 'hidden']">
                            <span v-for="(senses,i) in entries.senses" :key="senses.id" v-bind:class="[i < 1 ? 'show' : 'hidden']">
                                <span v-for="(synonyms,i) in senses.examples" :key="synonyms.id">
                                    <span style="display:flex">
                                    <b class="text-black font-normal pb-0 font-600" style="white-space: normal;" v-if="i <= 1">{{synonyms.text}},</b>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
                    <p style="text-align:center;font-size: 1rem;font-weight:600" class="text-blue pb-0 pt-1">Pronunciation</p>
                <h4 class="lan text-blue flex align-center" style="line-height:0;font-size: 1rem;">UK <span class="flex align-center text-black pl-3">{{results.word}}<span @click="UKVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;" ><Volume/></span></span></h4>
                <h4 class="lan text-blue flex align-center" style="line-height:0;margin-top:7px;font-size: 1rem;">US <span class="flex align-center text-black pl-3">{{results.word}}<span @click="USVoice()" class="pl-3 text-blue" style="margin-top:4px;cursor: pointer;"><Volume/></span></span></h4><br>
            </span>
        </span>
    </div>
</template>

<script>
import Volume from '../../../../assets/svg/volume.svg';
export default {
  components:{
      Volume
  },
   props:{
        info: {
           default: true 
        },
        showLoading: {
           default: true 
        },

   },
  data() {
    return {
       results:[],
       synonyms: null,
    }
  },
  computed: { 
  
  },
  mounted () {
      let element = document.querySelector('.student');
      if(this.showLoading != null){
        element.style.overflow = "hidden"
      } 
  },
  methods:{
        USVoice(){
                let speech = new SpeechSynthesisUtterance();
                speech.text = this.info.results[0].word;
                speech.pitch = 1;
                speech.volume = 1;
                speech.lang = "en-US";
                speech.rate = 1;
                speechSynthesis.speak(speech);
        },
        UKVoice(){
                let speech = new SpeechSynthesisUtterance();
                speech.text = this.info.results[0].word
                speech.pitch = 1;
                speech.volume = 1;
                speech.lang = "en-GB";
                speech.rate = 1;
                speechSynthesis.speak(speech);
        }
       
  },
  
 
};
</script>
<style>
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.lan svg{
    height: 22px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 280px;
  min-height: 390px;
  max-height: 390px;
  overflow-y: scroll;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0px 0;
  position: absolute;
  z-index: 111;
  top: -390px;
  left: -112px;
}
.tooltip .tooltiptext::-webkit-scrollbar {
  width: 0em;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>