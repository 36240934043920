<template>
    <div class="evaluation_header">
        <div class="study_guide_evaluation">
            <Alert background-color="#f0faef">
                <div class="flex justify-between align-center">
                     <md-content class="study_guide_body md-layout">
                        <div class="notes md-size-80">
                            Your performance is <span class="weight-600 text-red">NOT UP TO MARK</span> . Before attempting the next question in <span class="weight-600 text-black">Summarize Written Test</span> you <span class="weight-600 text-black">must</span> check the Study Guide one more time.
                        </div>
                        <div class="button_body md-size-20">
                            <span @click="ShowAlert()">
                                <button class="bg-green text-white">View Study guide</button>
                                <!-- <md-tooltip v-if="item.is_default === 0" id="set_explation_tooltip" md-direction="top">Your study guide has not been approved by the admin now</md-tooltip> -->
                            </span>
                        </div>
                    </md-content>
                </div>
            </Alert>
        </div>
        <PopupAlert @success="ViewDeatils" :message="message" :active.sync="active"/>
        <DetailsView :question="question" :showStudyGuideDialog.sync="showMediaDialog"/>
    </div>
</template>

<script>
import PopupAlert from '@/components/molecule/question-and-test-management/material/StudyGuide/PopupAlert';
import DetailsView from '@/components/molecule/question-and-test-management/material/StudyGuide/DetailsView'
export default {
    components : {
        PopupAlert,
        DetailsView 
    },
    props:{
        question: {
           default: true 
        }
    },
    data() {
        return {
           active: false,
           showMediaDialog: false,
           message: 'Do you want to check Study Guide Again To Improve <br> Your Experience ?'
        }
    },
    methods:{ 
        ShowAlert(){
            this.active = true;
            this.showMediaDialog = false
        },
        ViewDeatils(){
             this.showMediaDialog = true;
        },
    },
}
</script>
