import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

    this.gradient2.addColorStop(0, 'rgba(83,154,168, 0.9)')
    this.gradient2.addColorStop(0.5, 'rgba(83,154,168, 0.5)');
    this.gradient2.addColorStop(1, 'rgba(83,154,168, 0.2)');
    
    this.gradient.addColorStop(0, 'rgba(247,108,6, 0.9)')
    this.gradient.addColorStop(0.5, 'rgba(247,108,6, 0.25)');
    this.gradient.addColorStop(1, 'rgba(247,108,6, 0)');
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    
    this.renderChart(this.chartData, this.options)
    
  }
}