<template>
  <div class="chart" >
    <span class="font-bold md_high">High</span>
    <div class="" id="chart_body"></div>
    <line-chart class="chart_canvas" :width="300" :height="210" :chart-data="chartData"  :options="options"></line-chart>
    <span class="font-bold md_low">Low</span>
    <!-- {{options}} -->
  </div>
</template>

<script>
import LineChart from "./LineChart.js";
export default {
  name: "Home",
  components: {
    LineChart
  },
  props:{
      chartData: {
        type: Object,
        required: true
      },
      options: {
        type: Object,
        required: true
      },
  },
  data() {
    return {
      datacollection: null,
      words:[],
      Offset: [],
      Word: '',
      label: '',
      Off: [],
      
    }
  },   
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.md_high{
  margin-top: 16px;
  position: absolute;
  margin-left: 4px;
}
.md_low{
  position: absolute;
  margin-left: 6px;
  margin-bottom: -138px;
  bottom: 0;
}
.chart{
  position: relative;
}
.chart_canvas{
  position: absolute;
    z-index: 1111111;
    margin: 0px 20px 0px 22px;
    width: 96.5%;
}
</style>
