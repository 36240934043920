<template>
    <div class="evaluation_prograss pt-4">
        <Alert classObject="rounded-lg p-2">
            <div class="flex justify-between align-center pb-4">
                <div class="flex align-center">
                    <h2 style="margin-top: -3px;">Your Repeat Sentence Content Recognition</h2>
                    <span style="background: rgb(164, 15, 188)" class="text-white back-blue br-6 pl-1 pr-1 ml-2">Analysis</span>
                </div>
                <div class="flex">
                    <span class="font-bold br-6 p-1 pl-2 pr-2 mr-2 mr-d dot_green flex align-center">
                        <span class="grid">Good 
                          <p class="font-normal text-gray">{{this.goodPre.toFixed(0) || 0}}%</p>
                       </span>
                    </span>
                    <span class="font-bold br-6 p-1 mr-2 pl-2 pr-2 mr-d dot_avg flex align-center">
                        <span class="grid">Average 
                          <p class="font-normal text-gray">{{this.avgPre.toFixed(0) || 0}} %</p>
                       </span>
                    </span>
                    <span class="font-bold br-6 p-1 mr-2 pl-2 pr-2 mr-d dot_red flex align-center">
                        <span class="grid">Poor 
                          <p class="font-normal text-gray">{{this.poorPre.toFixed(0) || 0}} %</p>
                       </span>
                    </span>
                    <span class="font-bold br-6 p-1 mr-2 pl-2 pr-2 mr-d dot_gray flex align-center">
                        <span class="grid">Pause 
                          <p class="font-normal text-gray">{{this.pausePre.toFixed(0) || 0}} %</p>
                       </span>
                    </span>
                </div>
            </div>
            <div class="b-d pt-5 p-1 pb-5 b-d-c br-6">
                <div class="flex align-center recon_body">
                        <div v-for="(Words ,index) in answer_sheet.answer_sheet_details.evaluation_details" :key="index"> 
                            <div v-for="x in Words.NBest" :key="x.id" class="flex align-center flex_-wrap">
                                <div class="tooltip grid pl-2 pr-2 font-bold br-4 m-1 is-blue" v-for="y in x.Words" :key="y.id" :class=" y.PronunciationAssessment.AccuracyScore >= 80 ? 'is-green' : 'is-poor' &&  y.PronunciationAssessment.AccuracyScore >= 60 ? 'is-avg' : 'is-poor' &&  y.PronunciationAssessment.AccuracyScore >= 40 ? 'is-red' : 'is-poor'">                                                           
                                    <span class="text-center" v-if="y.PronunciationAssessment.AccuracyScore >= 40 || y.PronunciationAssessment.ErrorType !== 'Omission'" v-html="y.Word.toString().replace(/[p/>]/g, '').replace(/[.]/g, '').replace('</', '')" @click="wordFilter(y.Word)"></span>
                                   
                                    <span class="pt-2 pb-2" v-if="y.PronunciationAssessment.AccuracyScore < 40 || y.PronunciationAssessment.ErrorType === 'Omission'">
                                       <!-- <span class="block text-center" v-html="y.Word"></span> -->
                                       <span>/</span>
                                    </span>
                                     <span class="text-center" v-else>{{y.PronunciationAssessment.AccuracyScore}}%</span>
                                    <span :md-active="showDialog"  class="tooltiptext recon_tooptip pt-2 pr-2 pl-2 br-6 flex align-center justify-center" md-direction="top">
                                       <Oxford :info="results" :showLoading="showLoading" v-if="results !== null"/>
                                        <span class="loader_body" v-if="showLoading">
                                         <md-progress-spinner  :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                                        </span>
                                    </span>
                                    <!-- <md-tooltip class="recon_tooptip pt-2 pr-5 pl-5 br-6" md-direction="top">
                                        <b class="text-black">Adipiscing <small class="pl-3">(v)</small></b>
                                        <p class="text-black font-normal pb-2">Meaning, Meaning</p>
                                        <p class="text-gray">Pronunciation</p>
                                        <b class="text-blue flex align-center" style="line-height:0;margin-top:-2px">UK <span class="flex align-center text-black pl-3">Adepessing <span class="pl-3 text-gray" style="margin-top:4px" ><Volume/></span></span></b>
                                        <b class="text-blue flex align-center" style="line-height:0;margin-top:-5px">US <span class="flex align-center text-black pl-3">Adepessing <span class="pl-3 text-gray" style="margin-top:4px"><Volume/></span></span></b><br>
                                    </md-tooltip> -->
                                </div>
                                <!-- <span class="back-gray pl-1 text-gray pr-1 br-3" style="font-size:20px">/</span> -->
                            </div>  
                        </div>                                                                                                     
                </div>
                <div class="pt-8 p-1 pb-2 br-6 flex" style="align-items:flex-start">
                      <div>
                        <h3>Analysis at a glance</h3>
                        <p class="text-gray">number of words
                           <span class="text-black">{{wordLength}}</span>
                        </p>
                      </div>
                      <div class="pl-8 mt-3">                   
                            <div class="" style="position:absolute;width:40%">
                                <div class="progress mr-0-auto text-center">
                                    <!-- good -->
                                    <p v-if="this.goodNum != 0" class="pb-1 text-green font-bold title_text" v-bind:style="{ width: this.goodPre + '%' }">good</p>
                                    <div v-if="this.goodNum != 0" class="relative green" v-bind:style="{ width: this.goodPre + '%' }">
                                        <p class="pr_pr-count">{{this.goodPre.toFixed(0) || 0}} %</p>
                                    </div>
                                    <p v-if="this.goodNum != 0" class="pb-1 text-green font-bold total_text" v-bind:style="{ width: this.goodPre + '%' }">{{this.goodNum || 0}}</p> 
                                    <!-- avg -->
                                    <p v-if="this.avgNum != 0" class="pb-1 text-avg font-bold title_text" v-bind:style="{left: this.goodPre + '%', width: this.avgPre + '%'}">Average</p>
                                    <div v-if="this.avgNum != 0" class="relative avg" v-bind:style="{ width: this.avgPre + '%' }">
                                        <p class="pr_pr-count">{{this.avgPre.toFixed(0) || 0}} %</p>
                                    </div>
                                    <p v-if="this.avgNum != 0" class="pb-1 text-avg font-bold total_text" v-bind:style="{left: this.goodPre + '%', width: this.avgPre + '%' }">{{this.avgNum || 0}}</p> 
                                    <!-- poor -->
                                    <p v-if="this.poorNum != 0" class="pb-1 text-red font-bold title_text" v-bind:style="{left: this.goodPre + this.avgPre + '%',width: this.poorPre  + '%'}">Poor</p>
                                    <div v-if="this.poorNum != 0" class="relative poor" v-bind:style="{ width: this.poorPre + '%' }">
                                        <p class="pr_pr-count">{{this.poorPre.toFixed(0) || 0}} %</p>
                                    </div>
                                    <p v-if="this.poorNum != 0" class="pb-1 text-red font-bold total_text" v-bind:style="{left: this.goodPre + this.avgPre + '%', width: this.poorPre + '%' }">{{this.poorNum || 0}}</p> 
                                    <!-- pause -->
                                    <p v-if="this.pauseNum != 0" class="pb-1 text-gray font-bold title_text" v-bind:style="{left: this.goodPre + this.avgPre + this.poorPre + '%', width: this.pausePre + '%' }">pause</p>
                                    <div v-if="this.pauseNum != 0" class="relative pause" v-bind:style="{ width: this.pausePre + '%' }">
                                        <p class="pr_pr-count">{{this.pausePre.toFixed(0) || 0}} %</p>
                                    </div>
                                    <p v-if="this.pauseNum != 0" class="pb-1 text-gray font-bold total_text" v-bind:style="{left: this.goodPre + this.avgPre + this.poorPre + '%', width: this.pausePre + '%' }">{{this.pauseNum || 0}}</p> 

                                </div>
                            </div>
                        
                      </div>
                </div>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
import axios from 'axios';
import Oxford from '../dic/Oxford.vue';
import { mapActions,mapGetters} from 'vuex';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
    components : {
        Alert,
        //Volume,
        Oxford
    },
    props:{
       answer_sheet: {
           default: true 
       }
    },
    data() {
        return {
          amount: 65,
          answer_sheet_details: '',
          wordLength: '',
          evaluation_details: '',
          good: [],
          TotalWord2: '',
          TotalWord3: '',
          goodNum: [],
          avgNum:[],
          poorNum: [],
          pauseNum: [],
          goodPre: [],
          avgPre:[],
          poorPre: [],
          pausePre: [],
          results: '',
          showLoading: true,
        }
    },
    watch:{
      answer_sheet: {
        handler(val){
        // answer_sheet
        this.question = val.answer_sheet_details
        this.evaluation_details = this.question.evaluation_details
        },
      }  
    },
    computed: { 
        ...mapGetters({
        getResults: "result/getResults",
        }),
    },
    methods:{
        wordFilter(word) {
            //const value = val.Word
            this.showDialog = true
            const app_id = "e0193320";
            const app_key = "6152d94063107a53df1fc98e7a5a8c24";
            var headers = new Headers()
            headers.append('Authorization', 'Basic ' + app_id + ":" + app_key);
            var options = {
                url: 'https://od-api.oxforddictionaries.com/api/v2/entries/en-gb/'+word.toString().replace(/[[p^>]/g, '').replace(/[.]/g, '').replace('</', ''),
                method: 'GET',

            };
            this.showLoading = true
            axios.request(options).then((response) => {
                this.results = response.data
                this.showLoading = false
            })
            

        },
        ...mapActions({
            actShowResult: "result/actShowResult",
            actResultList: "result/actResultList"
        }),
    },
    created() {
      this.answer_sheet_id = localStorage.getItem('answer_sheet_id');
      console.log('sss',this.answer_sheet_id)
      this.actShowResult(this.answer_sheet_id)
          .then(res => 
            res.answer_sheet_details.evaluation_details.map(item  => {
                console.log('details',item)
                item.NBest.map(item  => {
                    this.wordLength = item.Words.length;
                    // good
                    const good = item.Words.filter((items)  => {
                        if(items.PronunciationAssessment.AccuracyScore >= 80){
                             return items.PronunciationAssessment.AccuracyScore !== undefined 
                        }
                    })
                    this.goodPre = good.length * 100 / this.wordLength;
                    this.goodNum = good.length
                    console.log(this.goodPre);
                    // avg
                    const avg = item.Words.filter((items)  => {
                        if(items.PronunciationAssessment.AccuracyScore >= 60 && items.PronunciationAssessment.AccuracyScore < 80){
                             return items.PronunciationAssessment.AccuracyScore !== undefined 
                        }
                    })
                    this.avgPre = avg.length * 100 / this.wordLength;
                    this.avgNum = avg.length;
                    console.log(this.avgPre);
                    // poor 
                    const poor = item.Words.filter((items)  => {
                        if(items.PronunciationAssessment.AccuracyScore >= 40 && items.PronunciationAssessment.AccuracyScore < 60){
                             return items.PronunciationAssessment.AccuracyScore !== undefined 
                        }
                    })
                    this.poorPre = poor.length * 100 / this.wordLength;
                    this.poorNum = poor.length;
                    console.log(this.poorPre,this.poorP);
                    // pause 
                        const pause = item.Words.filter((items)  => {
                            if(items.PronunciationAssessment.ErrorType != "Omission"){
                                if(items.PronunciationAssessment.AccuracyScore < 40){
                                    return items.PronunciationAssessment.AccuracyScore 
                                }
                            } else {
                                return items.PronunciationAssessment.ErrorType
                            }
                        })
                        this.pausePre = pause.length * 100 / this.wordLength;
                        this.pauseNum = pause.length;
                        console.log(this.pausePre,this.pauseNum,pause)
                   
                })
            })  
      )
    },

}
</script>
<style scoped>
.is-green{
    background: #dbf2d9 !important;
    color: #28ba27 !important;
}
.green{
     background: #28ba27 !important;
}
.avg{
   background: #1a1ef3 !important; 
}
.poor{
    background-color: red !important;
}
.pause{
    background: #a4a4a4 !important;
}
.title_text {
    position: absolute;
    margin-bottom: 210px;
    margin-top: -14px;
}
.is-red{
    background: #fbdde2 !important;
    color: #d93025 !important;
}
.is-poor{
    background: #dbdbdbab !important;
    color: #767676 !important;
    text-align: center;
}
.is-avg{
    text-align: center;
    background: #dee6fd;
    color: #6288f3;
}
.flex_-wrap{
    width: 100%;
    flex-shrink: 0;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.tooltip{
    display: grid;
    border-bottom: 0px dotted black;
}
.loader_body{
    position: absolute;
    background: #fff;
    height: 100%;
    z-index: 111111;
    width: 280px;
    height: 100%;
    padding-top: 60%;
}
.progress {
    height: 1rem;
    line-height: 0;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}
.align-center{
    align-items: center !important;
}
.progress{
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}
.contents{
    display: contents;
}
.total_text{
    position: absolute;
    margin-top: 30px;
}

</style>

